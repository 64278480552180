<template>
  <v-container>
    <v-form ref="form">
      <v-text-field
        v-model="reward.key"
        label="Name"
        readonly
      ></v-text-field>

      <v-text-field
        label="Description"
        v-model="reward.description"
        readonly
      ></v-text-field>

      <v-text-field
        label="Points"
        v-model="reward.points"
      ></v-text-field>

      <v-text-field
        label="Created At"
        v-model="reward.createdAt"
      ></v-text-field>

      <v-btn @click="$router.push({ name: 'MobileRewardIndex' })" color="primary mr-2">Back</v-btn>
    </v-form>
  </v-container>
</template>

<script>
export default {
  props: {
    reward: Object
  },
  methods: {
    
  }
}
</script>