<template>
  <v-card>
    <v-card-title>
      Edit Reward
    </v-card-title>

    <RewardFrom 
      :reward="reward"
      type="UPDATE"
    />
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
import RewardFrom from "./form"

const service = new RestResource();
export default {
  components: {
    RewardFrom
  },
  data() {
    return {
      reward: {}
    };
  },

  mounted() {
    this.loadProject();
  },

  methods: {
    loadProject() {
      this.$setLoader();

      service.fetchRewards({ _id: this.$route.params.id }).then(r => {
        this.reward = r.data[0];
        this.$disableLoader();
      });
    },
  }
};
</script>